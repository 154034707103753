import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import { Layout, SEO, Container } from "../components"
import styles from "./index.module.scss"
import { childImageSharpType } from "../types/types"
import { isTablet } from "../utils/responsive"

const BuyNowPage = ({ data }) => {
  const { 
    frontmatter: {
      title,
      subTitle,
      description,
      humbleBumbleSrc,
      buyLinks
    }
  } = data.markdownRemark
  return (
    <Layout className={styles.buyNow}>
      <SEO title={title} description={description} />
      <Container className={classnames(styles.container, styles.buynowContainer)}>
        <div className={styles.intro}>
          <h1>{title}</h1>
          <h4>{subTitle}</h4>
          <iframe 
            className={styles.humbleBumble}
            width="550"
            height="327"
            scrolling={isTablet() ? "yes" : "no"}
            frameBorder="0"
            title={title}
            allowtransparency="true"
            src={humbleBumbleSrc}
          />
          <div className={styles.availableOn}>
            <h2>Also available on:</h2>
            <ul>
            {
              buyLinks.map((logo, index) => (
                <li key={`link_${index}`}>
                  <a
                    href={logo.link}
                    title={title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img 
                      className={styles.availableOnImg}
                      fixed={logo.imgSrc.childImageSharp.fixed}
                      alt={title}
                    />
                  </a>
                </li>
              ))
            }
            </ul>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query buyNowPageQuery {
    markdownRemark(frontmatter: { path: { eq: "/buy-now" } }) {
      frontmatter {
        title
        subTitle
        description
        humbleBumbleSrc
        buyLinks {
          imgSrc {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          }
          link
          title
        }
      }
    }
  }
`

BuyNowPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        subTitle: PropTypes.string,
        humbleBumbleSrc: PropTypes.string,
        buyLinks: PropTypes.arrayOf(PropTypes.shape({
          imgSrc: PropTypes.shape({
            childImageSharp: childImageSharpType,
          }),
          link: PropTypes.string,
          title: PropTypes.string
        }))
      })
    }).isRequired
  }).isRequired
}

export default BuyNowPage
